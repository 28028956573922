<template>
  <v-card class="cardDialog" style="height: 520px !important">
    <v-container style="background-color: #9c9c9c">
      <v-row align="center" justify="center">
        <h3 class="whiteText" style="margin-top: 30px">
          Ups! Se produjo un error
        </h3>
      </v-row>
      <v-row>
        <v-col align="center" justify="center">
          <v-img
            id="imagePetSad"
            src="@/assets/img/pet_sad.svg"
            alt="Mascota saludando"
            position="center center"
          />
        </v-col>
      </v-row>
      <v-row class="headerRoundedWhite"> &nbsp; </v-row>
      <v-row align="center" style="background-color: white">
        <v-col align="center" justify="center">
          <p>{{ messageError }}</p>
        </v-col>
      </v-row>
      <v-row class="pb-6" align="center" style="background-color: white">
        <v-col align="center" justify="center">
          <v-btn bottom depressed rounded class="btnAmarillo" @click="close()">
            Cerrar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "ErrorRequest",
  props: {
    dialog: { type: Boolean, default: false },
  },
  data: () => ({
    defaultTest: true,
    messageError: null,
  }),
  mounted() {
    this.messageError =
      "Por favor volvé a intentar o contactate por WhatsApp\n" +
      "            con nuestro equipo de soporte.";
    let errorMessageState = this.$store.getters.getErrorMessage;
    if (errorMessageState !== "") {
      this.messageError = errorMessageState;
    }
  },
  methods: {
    ...mapActions(["resetStore"]),
    close() {
      this.$store.commit("ERROR_MODAL", false);
      this.$store.commit("SET_ERROR_MODAL_MSG", "");
    },
  },
};
</script>
