export const creditStatusLabels = Object.freeze({
  DRAFT: 'Procesando',
  PENDING: 'Pendiente',
  APPROVED: 'Aprobado',
  ACCEPTED: 'Aceptado',
  DOCUMENTING: 'Documentando',
  OPENED: 'En curso',
  REJECTED: 'Rechazado',
  CLOSED: 'Cerrado',
  DELAYED: 'Atrasado',
  BAD: 'Incobrable',
  COMPLETED: 'Finalizado'
});