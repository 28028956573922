<template>
  <v-dialog
    v-model="session"
    persistent
    width="500"
  >
    <v-container style="background-color: #9c9c9c">
      <v-row
        align="center"
        justify="center"
      >
        <h3
          class="whiteText"
          style="margin-top: 30px"
        >
          {{ title }}
        </h3>
      </v-row>
      <v-row>
        <v-col
          align="center"
          justify="center"
        >
          <v-img
            id="imagePetSad"
            :src="img"
            alt="Mascota saludando"
            position="center center"
            contain
          />
        </v-col>
      </v-row>
      <v-row class="headerRoundedWhite">

      </v-row>
      <div v-if="init">
        <v-row
          align="center"
          style="background-color: white"
        >
          <v-col
            align="center"
            justify="center"
          >
            <p>Tu sesión esta por finalizar en {{ second }} segundos.</p>
          </v-col>
        </v-row>
        <v-row
          align="center"
          style="background-color: white"

        >
          <v-col
            align="center"
            justify="center"
          >
            <v-btn
              bottom
              depressed
              rounded
              class="btn"
              @click="closeSession()"
            >
              Cerrar Sesión
            </v-btn>
          </v-col>
          <v-col
            align="center"
            justify="center"
          >
            <v-btn
              bottom
              depressed
              rounded
              class="btn btnAmarillo"
              @click="close()"
            >
              Continuar
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row
          align="center"
          style="background-color: white"
        >
          <v-col
            align="center"
            justify="center"
          >
            <p>Por cuestiones de seguridad dimos por finalizada tu sesión.</p>
          </v-col>
        </v-row>
        <v-row
          align="center"
          style="background-color: white"
        >
          <v-col
            align="center"
            justify="center"
          >
            <v-btn
              bottom
              depressed
              rounded
              class="btnAmarillo"
              @click="goHome()"
            >
              Ingresar de nuevo
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-dialog>
</template>

<script>
  import { homeRoute } from "@/helper/homeRoute";
  import petGlasses from "@/assets/img/pet_sad.svg";
  import prestercito_lupa from "@/assets/img/prestercito_lupa.svg";
  export default {
    name: "WarningTimeout",
    props: ['value'],
    data() {
      return {
        time: 30000,
        session:false,
        title: "Seguís ahí?",
        init: true,
        img: prestercito_lupa,
        timerId: null,
      }
    },
    computed: {
      second() {
        return this.time / 1000;
      }
    },
    created() {
      this.timerId = setInterval(() => {
        this.time -= 1000;
        if (this.time < 1) {
          this.time = 10000;
          this.init =  false;
          this.title = "Cerramos tu sesión";
          this.img = petGlasses;
          this.$store.commit('SET_AUTHENTICATED', false, null);
          clearInterval(this.timerId);
        }
      }, 1000);
    },
    mounted() {
      this.session = this.value;
    },
    methods: {
      close() {
        this.session = false;
        clearInterval(this.timerId)
        this.$emit('input', false);
      },
      goHome() {
        this.$router.replace({name: homeRoute.HOME})
      },
      closeSession() {
        this.$store.commit('SET_AUTHENTICATED', false, null);
        clearInterval(this.timerId);
        this.$router.replace({name: homeRoute.HOME})

      }
    }
  };
</script>
