import Solicitud from "@/views/solicitud/Solicitud";
import auth from "@/middleware/auth";
import Failed from "@/views/solicitud/components/Failed";
import {messageClient} from "@/helper/messageClient";

const steps_loan = [
    {
        name: 'request_new_loan',
        path: '/registro/iniciar',
        component: Solicitud,
        props: { step: 1 , card: 2},
    },
    {
        name: 'request_login',
        path: '/registro/acceso',
        component: Solicitud,
        props: { step: 1 , card: 1, title:"Registrarse"},
    },
    {
        name: 'request_signin',
        path: '/registro/ingresar',
        component: Solicitud,
        props: { step: 1 , card: 1, title:"Ingresar"},
    },
    {
        name: 'request_info',
        path: '/registro/datos',
        component: Solicitud,
        props: { step: 1 , card: 2},
    },
    {
        name: 'request_info_confirmation',
        path: '/registro/datos_confirmacion',
        component: Solicitud,
        props: { step: 1 , card: 4},
        meta: {
            middleware: [auth]
        }
    },
    {
        name: 'request_error',
        path: '/registro/app',
        component: Solicitud,
        props: { step: 1 , card: 0},
        meta: {
            middleware: [auth]
        }
    },
    {
        name: 'request_send_link',
        path: '/registro/link',
        component: Solicitud,
        props: { step: 1 , card: 3},
        meta: {
            middleware: [auth]
        }
    },
    {
        name: 'request_quotation',
        path: '/registro/confirmacion_monto',
        component: Solicitud,
        props: { step: 2},
        meta: {
            middleware: [auth]
        }
    },
    {
        name: 'request_personal_information',
        path: '/registro/celular',
        component: Solicitud,
        props: { step: 3},
        meta: {
            middleware: [auth]
        }
    },
    {
        name: 'request_personal_documentation_main',
        path: '/registro/dni',
        component: Solicitud,
        props: { step: 4},
        meta: {
            middleware: [auth]
        }
    },
    {
        name: 'request_personal_documentation_front',
        path: '/registro/dni/foto_frente',
        component: Solicitud,
        props: { step: 5, card:1},
        meta: {
            middleware: [auth]
        }
    },
    {
        name: 'request_personal_documentation_front_confirmation',
        path: '/registro/dni/foto_frente/confirmacion',
        component: Solicitud,
        props: { step: 5, card:2},
        meta: {
            middleware: [auth]
        }
    },
    {
        name: 'request_personal_documentation_back',
        path: '/regitro/dni/foto_dorso',
        component: Solicitud,
        props: { step: 5, card:3},
        meta: {
            middleware: [auth]
        }
    },
    {
        name: 'request_personal_documentation_back_confirmation',
        path: '/registro/dni/foto_dorso/confirmacion',
        component: Solicitud,
        props: { step: 5, card:4},
        meta: {
            middleware: [auth]
        }
    },
    {
        name: 'request_personal_documentation_selfie',
        path: '/registro/dni/selfie',
        component: Solicitud,
        props: { step: 5, card:5},
        meta: {
            middleware: [auth]
        }
    },
    {
        name: 'request_personal_documentation_selfie_confirmation',
        path: '/registro/dni/selfie/confirmacion',
        component: Solicitud,
        props: { step: 5, card:6},
        meta: {
            middleware: [auth]
        }
    },
    {
        name: 'request_personal_documentation_address',
        path: '/registro/domicilio',
        component: Solicitud,
        props: { step: 5, card:7},
        meta: {
            middleware: [auth]
        }
    },
    {
        name: 'request_disburstment_data',
        path: '/registro/cbu',
        component: Solicitud,
        props: { step: 6, card:1},
        meta: {
            middleware: [auth]
        }
    },
    {
        name: 'request_disburstment_debit_card',
        path: '/registro/tarjeta',
        component: Solicitud,
        props: { step: 6, card:2},
        meta: {
            middleware: [auth]
        }
    },
    {
        name: 'request_check_data',
        path: '/registro/confirmacion',
        component: Solicitud,
        props: { step: 7},
        meta: {
            middleware: [auth]
        }
    },
    {
        name: 'request_finish',
        path: '/registro/confirmacion_terminada',
        component: Solicitud,
        props: { step: 8},
        meta: {
            middleware: [auth]
        }
    },
    {
        name: 'request_failed',
        path: '/registro/error',
        component: Failed,
        props: true
    },
    {
        name: 'request_client_information',
        path: '/registro/solicitud-cliente',
        component: Solicitud,
        props: { step: 3},
        meta: {
            middleware: [auth]
        }
    },
    {
        name: 'request_loan_mail',
        path: '/registro/mail',
        component: Solicitud,
        props: { step: 1 , card: 5},
    }
]

export default steps_loan