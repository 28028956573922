export const homeRoute = Object.freeze({
    HOME: "home",
    ABOUT: "about",
    TERM_AND_CONDITION: "legal_tyc",
    ADHESION_CONTRACT: "legal_cda",
    PRIVATE_POLICY: "legal_pdp",
    INFORMATION_FINANCIAL: "legal_iuf",
    PERSONAL_LOAN: "legal_pl",
    CONSUMER_DEFENSE: "legal_cd",
    TERM_AND_CONDITION_CODE: 1,
    ADHESION_CONTRACT_CODE: 2,
    PRIVATE_POLICY_CODE:  3,
    INFORMATION_FINANCIAL_CODE:  4,
    PERSONAL_LOAN_CODE: 5,
    CONSUMER_DEFENSE_CODE: 6,
    SEND_TO_APP: "send_to_app"
});

