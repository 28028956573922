<template>
  <router-view :key="$route.path"/>
</template>
<script>
  export default {
    name: 'App',
    created: function () {
      if(this.$store.getters.getToken){
        axios.defaults.headers.common['Authorization'] = this.$store.getters.getToken;
      }
    }
  }
</script>

<style lang="scss">
@import "./assets/scss/material-dashboard.scss";

#app {
  background-color: #eee;
}
</style>