<template>
  <div>
    <v-app class="bodyHomeYellow">
      <v-toolbar
        class="failed__header"
        elevation="0"
        style="background: #FFF000"
      >
        <v-flex class="step__header-main-image">
          <v-img
            class="failed__main-logo"
            src="@/assets/logo.svg"
            alt="logo"
            position="center center"
            @click="$router.replace({ name: 'home' })"
          />
        </v-flex>
        <v-flex>
          <h1 class="text-right textHeaderDesktop">
            Yo te presto.
          </h1>
        </v-flex>
      </v-toolbar>
      <v-main class="step__main-body">
        <div class="templateDesktop">
          <v-container
            fluid
            class="failed__title"
          >
            <v-row class="align-center align-content-center">
              <v-col
                class="col-sm-12 col-md-6 col-12 text-center"
                align-self="center"
              >
                <v-card
                  class="templateFailDesktop failed__template--header"
                  style="background-color:#9C9C9C;"
                >
                  <v-col
                    align="center"
                    justify="center"
                  >
                    <h2
                      class="whiteText"
                      style="margin-top:30px;"
                    >
                      ¡Lo sentimos!
                    </h2>
                    <v-img
                      id="imagePetSad"
                      :src="img_failed"
                      alt="Mascota saludando"
                      max-height="90%"
                    />
                  </v-col>
                </v-card>
              </v-col>
              <v-col
                class="col-md-6 align-self-center col-sm-12 failed__txt"
                align="center"
                justify="center"
              >
                <h4 v-html="messageTxt"></h4>
                <v-btn
                  v-if="default_btn"
                  bottom
                  depressed
                  rounded
                  x-large
                  class="btnAmarillo"
                  @click="next()"
                >
                  cerrar
                </v-btn>
                <v-btn
                  v-else
                  bottom
                  depressed
                  rounded
                  x-large
                  class="btnAmarillo"
                  @click="goToWsp()"
                >
                  Contactate ahora
                  <v-img
                    class="imageWppAyuda"
                    src="@/assets/img/icon_whatsapp_ayuda.png"
                    alt="wpp"
                  />
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { homeRoute } from "@/helper/homeRoute";
import { messageClient } from "@/helper/messageClient";
import pet_sad from "@/assets/img/pet_sad.svg";
import pet_think from "@/assets/img/pet_thinking.svg";
import pet_warning_poster from "@/assets/img/prestercito_atencion_rojo.svg"

export default {
  name: "ErrorStep",
  props: ["codeDeny"],
  data: () => ({
    messageTxt: null,
    img_failed: pet_sad,
    default_btn: true,
    status_image: {
      11: {
        img: pet_sad,
      },
      12: {
        img: pet_sad,
      },
      13: {
        img: pet_warning_poster,
      },
      14: {
        img: pet_think,
      }

    }
  }),
  mounted() {
    this.messageTxt = messageClient.DEFAULT_DENY;
    this.img_failed = pet_sad;
    if(messageClient.DENY_CODES.hasOwnProperty(this.codeDeny)) {
      this.messageTxt = messageClient.DENY_CODES[this.codeDeny];
      this.default_btn = this.codeDeny !== 13;
      this.img_failed = this.status_image[this.codeDeny].img;
    }
    this.$gtm.trackEvent({
      noninteraction: false,
      event: "WEB_ARG_CREDIT_REJECTED",
      id_person: this.$store.getters.getUserForm,
    });
  },
  methods: {
    ...mapActions(["resetStore"]),
    next() {
      this.resetStore();
      this.$router.replace({ name: homeRoute.HOME });
    },
    goToWsp(){
      window.open("https://wa.me/process.env.VUE_APP_PHONE_CASHING");
    }
  },
};
</script>
