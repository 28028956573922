<template>
  <div>
    <div v-if="$screenIsSmall()">
      <div>
        <div style="background-color: #fff000">
          <v-row>
            <v-col align="center" justify="center">
              <v-img id="imageLogoHeader" src="@/assets/logo.svg" alt="logo" />
              <div>&nbsp;</div>
            </v-col>
          </v-row>
          <div class="headerRoundedWhite">&nbsp;</div>
        </div>
        <v-main class="divHomeMarginSides">
          <personal-allons v-if="data === 'personalloans'"></personal-allons>
          <terms-and-conditions
            v-if="data === 'termsandconditions'"
            :show-contract="data"
          ></terms-and-conditions>
          <terms-and-conditions
            v-if="data === 'adhesioncontract'"
            :show-contract="data"
          ></terms-and-conditions>
          <consumer-defense
            v-if="data === 'consumerdefense'"
          ></consumer-defense>
          <information-to-financial-users
            v-if="data === 'informationtofinancialusers'"
          ></information-to-financial-users>

          <privacy-policies
            v-if="data === 'privacypolicies'"
          ></privacy-policies>

          <div v-if="isText" v-html="data" />
        </v-main>
      </div>
    </div>
    <div v-else>
      <v-app class="bodyHomeYellow">
        <v-toolbar
          class="headerTopHome"
          elevation="0"
          style="height: 50px; flex: 0 0 auto"
        >
          <v-col cols="8">
            <v-img
              class="imageLogoHeaderDesktop mt-6"
              src="@/assets/logo.svg"
              alt="logo"
              @click="$router.replace({ name: 'home' })"
            />
          </v-col>
          <v-col cols="4">
            <h1
              class="textHeaderDesktop text-right"
              style="margin-right: 100px"
            >
              Yo te presto.
            </h1>
          </v-col>
        </v-toolbar>

        <v-main class="bodyHomeWhite" style="margin: 3% 0px 0px 0px">
          <personal-allons v-if="data === 'personalloans'"></personal-allons>
          <terms-and-conditions
              v-if="data === 'termsandconditions'"
              :show-contract="data"
          ></terms-and-conditions>
          <terms-and-conditions
              v-if="data === 'adhesioncontract'"
              :show-contract="data"
          ></terms-and-conditions>
          <consumer-defense
            v-if="data === 'consumerdefense'"
          ></consumer-defense>
          <information-to-financial-users
            v-if="data === 'informationtofinancialusers'"
          ></information-to-financial-users>

          <privacy-policies
            v-if="data === 'privacypolicies'"
          ></privacy-policies>

          <div v-if="isText" v-html="data" />
        </v-main>
      </v-app>
    </div>
  </div>
</template>
<script>
import { homeRoute } from "@/helper/homeRoute";
export default {
  components: {
    PrivacyPolicies: () => import("../legal/PrivacyPolicies"),
    PersonalAllons: () => import("../legal/PersonalAllons"),
    TermsAndConditions: () => import("../legal/TermsAndConditions"),
    AdhesionContract: () => import("../legal/AdhesionContract"),
    ConsumerDefense: () => import("../legal/ConsumerDefense"),
    InformationToFinancialUsers: () =>
      import("../legal/InformationToFinancialUsers"),
  },
  name: "LegalDocumentation",
  props: {
    legales: { type: Number, default: 1 },
  },
  data: () => ({
    data: null,
    isText: false,
  }),
  mounted() {
    if (this.legales === homeRoute.TERM_AND_CONDITION_CODE) {
      this.data = "termsandconditions";
    } else if (this.legales === homeRoute.ADHESION_CONTRACT_CODE) {
      this.data = "adhesioncontract";
    } else if (this.legales === homeRoute.PERSONAL_LOAN_CODE) {
      this.data = "personalloans";
    } else if (this.legales === homeRoute.PRIVATE_POLICY_CODE) {
      this.data = "privacypolicies";
    } else if (this.legales === homeRoute.INFORMATION_FINANCIAL_CODE) {
      this.data = "informationtofinancialusers";
    } else if (this.legales === homeRoute.CONSUMER_DEFENSE_CODE) {
      this.data = "consumerdefense";
    }
  },
  methods: {},
};
</script>
