export const userRoute = Object.freeze({
    USER_HOME: "gestion",
    MY_LOANS: "user_loan",
    STATUS_LOAN: "status_loan",
    NEW_LOAN: "new_loan",
    NEW_CREDIT: "new_credit",
    MY_DATA: "my_data",
    CONTACT_US: "contact_us"
});

