<template>
  <div>
    <v-app class="bodyHomeYellow">
      <v-toolbar
        class="headerTopHome"
        elevation="0"
        style="height: 50px; flex: 0 0 auto"
      >
        <v-container>
          <v-row>
            <v-col class="logoBox">
              <v-img
                class="imageLogoHeaderDesktop mt-6"
                src="@/assets/logo.svg"
                alt="logo"
                @click="$router.replace({ name: 'home' })"
              />
            </v-col>
            <v-col class="sloganBoxPrester">
              <h1
                class="textHeaderDesktop text-right"
                style="margin-right: 100px"
              >
                Yo te presto.
              </h1>
            </v-col>
          </v-row>
        </v-container>
      </v-toolbar>
      <v-main
        style="
          margin: 2% 30px 30px 40px;
          background: white;
          border-radius: 15px;
        "
      >
        <div id="documentation-data" class="bodyCenter">
          <v-container>
            <v-row class="mt-6" align="center" justify="center">
              <div class="h1-404">¡Ups! La página solicitada no existe...</div>
            </v-row>
            <v-row class="row no-gutters pt-6">
              <div class="col-md-6 col-sm-12">
                <div class="d-flex right ma-4">
                  <img
                    class="ml-auto"
                    src="@/assets/img/pet_sad.svg"
                    alt="Mascota"
                    contain
                    max-height="300"
                    min-height="300"
                  />
                </div>
              </div>
              <div class="col-md-6 col-sm-12 leftCenterTextCol mobileColToRow">
                <h4 class="subtitle">
                  HACÉ CLIC EN CONTINUAR PARA IR AL INICIO.
                </h4>
              </div>
            </v-row>
            <v-row class="footerCard pt-6">
              <v-col align="center" justify="center">
                <v-btn
                  bottom
                  depressed
                  rounded
                  x-large
                  class="btnAmarillo"
                  :to="{ name: homeRoute.HOME }"
                >
                  Continuar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { homeRoute } from "@/helper/homeRoute";
export default {
  name: "PageNotFound",
  data: () => ({
    homeRoute,
  }),
};
</script>