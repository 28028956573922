<template>
  <div>
    <v-app class="bodyHomeYellow">
      <v-toolbar
        class="toApp__header"
        elevation="0"
        style="background: #fff000"
      >
        <v-flex class="step__header-main-image">
          <v-img
            class="failed__main-logo"
            src="@/assets/logo.svg"
            alt="logo"
            position="center center"
            @click="$router.replace({ name: 'home' })"
          />
        </v-flex>
        <v-flex>
          <h1 class="text-right textHeaderDesktop">Yo te presto.</h1>
        </v-flex>
      </v-toolbar>
      <v-main class="step__main-body">
        <div class="templateDesktop">
          <ClientGoApp @nextLink="next()" />
        </div>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import ClientGoApp from "../../../components/manage_user/ClientGoApp";
import { mapGetters } from "vuex";

export default {
  name: "ToApp",
  components: { ClientGoApp },
  methods: {
    ...mapGetters(["getPreffix"]),
    next() {
      this.$gtm.trackEvent({
        noninteraction: false,
        event: `${this.getPreffix()}_LINK_TO_APP`,
        id_person: this.$store.getters.getUserForm,
      });
      if (this.$store.getters.getIsDevice) {
        window.open("https://findo.page.link/continue", "_blank", "popup=1");
        return;
      }
      let refPlayStore = this.$router.resolve({ name: "playstore" });
      window.open(refPlayStore.href, "_blank");
    },
  },
};
</script>