export const messageClient = Object.freeze({
    CLIENT_MSG: "Ya sos cliente Prester. Vamos a mostrarte las opciones que tenés para que puedas gestionar y pedir nuevos préstamos!",
    NOT_CLIENT_MSG: "No figuras en nuestra base de datos de clientes. Si querés solicitar un préstamo, hacé clic en “Continuar”",
    DENY_CODES: {
        '11': "Por el momento no podemos aprobar tu solicitud ya que contás con un atraso en tus pagos con alguna entidad. Ponete al día y volvé a intentar en 3 meses",
        '12':"Ya tenés un crédito vigente con Prester.<br> Podrás tramitar uno nuevo cuando hayas abonado la mitad del actual",
        '13': "No podemos otorgarte el crédito ya que tenés cuotas atrasadas. Contactanos y ponete al día",
        '14': "No podemos otorgarte el crédito ya que tenés otra solicitud en proceso.<br> Nos contactaremos con vos a la brevedad.<br> ¡Muchas gracias!"
    },
    DEFAULT_DENY: "En este momento no contamos con una oferta para vos.<br> Volvé a consultar en 3 meses"
});