<template>
  <div>
    <div>
      <v-row>
        <v-col
          cols="12"
          sm="3"
          md="3"
          align="center"
          justify="center"
        >
          <h3>
            Mis datos
          </h3>
          <v-img
            class="myData--pet-image"
            src="@/assets/img/yellow_pet_pen.svg"
            alt="Mascota"
          />
        </v-col>
        <v-col
          cols="12"
          sm="5"
          md="5"
          class="my-data__col--margin-top my-data__text-field"
        >
          <v-text-field
            label="Nombre y apellido"
            color="#6031DA"
            readonly
            :value="NameAndLastName"
          />
          <v-text-field
            label="Número de documento"
            color="#6031DA"
            readonly
            :value="DocumentId"
          />
          <v-text-field
            label="Fecha de nacimiento"
            color="#6031DA"
            readonly
            :value="BirthDate"
          />
          <v-text-field
            label="Teléfono"
            color="#6031DA"
            readonly
            :value="Telephone"
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
          md="4"
          class="my-data__col--margin-top my-data__text-field"
        >
          <v-text-field
            label="Domicilio actual"
            color="#6031DA"
            readonly
            :value="MainStreet"
          />
          <v-text-field
            label="Ciudad"
            color="#6031DA"
            readonly
            :value="State"
          />
          <v-text-field
            label="Provincia"
            color="#6031DA"
            readonly
            :value="Province"
          />
          <v-text-field
            label="Código Postal"
            color="#6031DA"
            readonly
            :value="ZipCode"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from "vuex";
  export default {
    name: "MyData",
    data: () => {
      return {
        NameAndLastName: "",
        DocumentId: "",
        Telephone: "",
        BirthDate: "",
        MainStreet: "",
        Province: "",
        State: "",
        ZipCode: ""
      }
    },
    mounted() {
      this.finalMethod();
    },
    methods: {
      ...mapActions(["openLoader", "closeLoader"]),
      ...mapGetters(["getDataUser"]),
      checkUserAddress() {
        this.$apiserver.getUserData('Address')
        .then((data) => {
          if (data.length > 0) {
            const info = data[0];
            this.MainStreet = JSON.parse(info.data).calle + ' ' + JSON.parse(info.data).numero;
            this.Province = JSON.parse(info.data).provincia;
            this.State = JSON.parse(info.data).localidad;
            this.ZipCode = JSON.parse(info.data).codPostal;
          }
        })
        .catch((error)=>{
          console.log(error);
          this.openLoader("¡Ups! Tuvimos un inconveniente. Por favor, inténtalo más tarde.");
        }).finally(()=>{
          this.closeLoader()
        })
      },
      checkUserData() {
        let user = this.getDataUser();
        this.NameAndLastName = `${user._name} ${user._lastname}`;
        this.DocumentId = user._dni;
        this.Telephone = (user._phone) || '-';
        this.BirthDate = user._birthday;
      },
      finalMethod() {
        this.openLoader();
        this.checkUserAddress();
        this.checkUserData();
      }
    },
  }
</script>
<style>
  .my-data__text-field > .v-text-field__slot input {
    border-color: #6031DA !important;
    font-weight: bold !important;
    color: #6031da !important;
    font-size: 1.5625rem !important;
    text-align: center !important;
  }

  .my-data__text-field > .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border-color: #6031DA !important;
  }

  .my-data__text-field > .v-text-field > .v-input__control > .v-input__slot:before {
    border-color: inherit;
    border-style: solid;
    border-width: medium 0 0 0;
  }

  .my-data__text-field > .theme--light.v-text-field:not(.v-input--has-state):hover
    > .v-input__control
    > .v-input__slot:before {
    border-color: #6031DA !important;
  }

  .my-data__text-field > .v-input__is-focused {
    border-color: #6031DA !important;
  }

  .my-data__text-field > .v-input__slot {
    font-size: 25px !important;
  }

  .my-data__text-field > .v-text-field.centered-input .v-label {
    left: 50% !important;
    transform: translateY(-20px) translateX(-50%) !important;
  }

  .my-data__text-field > .v-toolbar__content {
    color: #000000 !important;
  }

  .my-data__text-field > .theme--light.v-input {
    color: #6031DA !important;
  }

  .my-data__text-field > .v-input--is-label-active .v-input--is-dirty .v-input--is-focused .v-input--is-readonly .theme--light .v-text-field .v-text-field--is-booted {
    caret-color: #6031DA !important;
  }

  .my-data__text-field label {
    font-size: 25px !important;
    padding-top: 1px !important;
  }
</style>