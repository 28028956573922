<template>
  <div>
    <v-container
      fluid
      class="failed__title"
    >
      <v-row class="align-center align-content-center">
        <v-col
          class="col-sm-12 col-md-6 col-12 text-center"
          align-self="center"
        >
          <v-col
            align="center"
            justify="center"
            class=""
          >
            <h2
              class="purple--text goApp__title-size"
            >
              Sólo unos pasos más
            </h2>
            <v-img
              src="@/assets/img/yellow_pet_glass.svg"
              alt="Mascota saludando"
              contain
              max-width="40%"
            />
          </v-col>
        </v-col>
        <v-col
          class="col-md-6 align-self-center col-sm-12 col-12 clientGoApp__txt"
          align="center"
          justify="enter"
        >
          <h3 style="font-size: 1.540rem;">
            Para completar tu solicitud, necesitamos validar algunos datos en la app.
          </h3>
          <v-col class="image-toApp--box toApp__img-qr">
            <v-img
              src="@/assets/img/qr_findo_app.png"
              max-width="30%"
            />
            <v-btn
              bottom
              depressed
              rounded
              x-large
              class="btnNegroBlanco"
              style="margin-top: 2%"
            >
              escaneá y descargá la app
            </v-btn>
          </v-col>
          <div class="toApp__go-playstore">
            <v-btn
              bottom
              depressed
              rounded
              x-large
              class="btnAmarillo"
              style="margin-top: 15%"
              @click="$emit('nextLink')"
            >
              continuar mi solicitud
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  export default {
    name: "ClientGoApp",
    mounted() {
      this.$gtm.trackEvent({
        noninteraction: false,
        event: `${this.getPreffix()}_SEND_TO_APP`,
        id_person: this.$store.getters.getUserForm,
      });
    },
  }
</script>

<style scoped>

</style>