import auth from '@/middleware/auth';
import { apiRoute } from "@/helper/apiRoute";
const api = [
    {
        path: `/registro/${apiRoute.BCRA}/:data(\\d+)?`,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: `/registro/${apiRoute.LOGIN}`,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: `/registro/${apiRoute.SEND_SMS}`,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: `/registro/${apiRoute.VALIDATE_PHONE}`,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: `/registro/${apiRoute.SINGUP}`,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: `/registro/${apiRoute.VALIDATE}`,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: `/registro/${apiRoute.CREDIT_CONFIG}`,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: `/registro/${apiRoute.IMAGE}`,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: `/registro/${apiRoute.CREATE_FORM}`,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: `/registro/${apiRoute.CREDIT_REQUIRE}`,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: `/registro/${apiRoute.FINISH_CREDIT}`,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: `/registro/${apiRoute.PAYMENT_REGISTRATION}`,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: `/registro/${apiRoute.SET_DATA}`,
        meta: {
            middleware: [auth]
        }
    },
    {
      path: `/registro/${apiRoute.LIST_ALL_PERSONAL_CREDITS}`,
      meta: {
          middleware: [auth]
      }
    },
    {
      path: `/registro/${apiRoute.GET_NEW_CREDIT}`,
      meta: {
          middleware: [auth]
      }
    },
    {
      path: `/registro/${apiRoute.GET_USER_DATA}`,
      meta: {
          middleware: [auth]
      }
    },
    {
        path: `/registro/${apiRoute.SET_INFO_USER}`,
        meta: {
            middleware: [auth]
        }
    },
]

export default api