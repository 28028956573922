export const photoText = Object.freeze({
    CELLPHONE:{
        MAIN_TXT: "PARA TOMAR LAS FOTOS TE PEDIREMOS ACCESO A LA CÁMARA.",
        PHOTO_FRONT_TXT: "Sacale una foto al frente de tu DNI",
        BTN_UPLOAD: "Tomar foto",
        BTN_UPLOAD_AGAIN: "Tomar foto",
        PHOTO_BACK_TXT: "Sacale una foto a la parte de atrás de tu DNI",
        PHOTO_SELFIE_TXT: "SACATE UNA SELFIE CON EL DNI EN MANO, ASÍ COMPROBAREMOS QUE SOS LA MISMA PERSONA"

    },
    DESKTOP:{
        MAIN_TXT: "PODRÁS CARGAR LAS FOTOS DIRECTO DESDE TU COMPUTADORA.",
        PHOTO_FRONT_TXT: "Cargá una foto del frente de tu DNI.",
        BTN_UPLOAD: "Cargar",
        BTN_UPLOAD_AGAIN: "Cargar otra foto",
        PHOTO_BACK_TXT: "Cargá una foto de la parte de atrás de tu DNI.",
        PHOTO_SELFIE_TXT: "CARGÁ UNA SELFIE CON EL DNI EN MANO, ASÍ COMPROBAREMOS QUE SOS LA MISMA PERSONA."
    }
});