export const steps = Object.freeze({
    LOAN_REQUEST: 1,
    QUOTATION: 2,
    PERSONAL_INFORMATION: 3,
    MAIN_DOCUMENTATION: 4,
    DOCUMENTATION: 5,
    DISBURSMENT: 6,
    CHECK_DATA: 7,
    FINISH_LOAN: 8
});

