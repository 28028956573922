import {apiRoute} from "@/helper/apiRoute";

export default class ApiServer {
    authToken;
    #min;
    #max;
    #step;

    constructor(routes) {
        this.routes = routes;

        this.#min = null;
        this.#max = null;
        this.#step = null;

        this.getGeneralRates().then( (data) => {
            this.#min = parseInt(data.data.minPaymentPeriodAmount);
            this.#max = parseInt(data.data.maxPaymentPeriodAmount);
            this.#step = parseInt(data.data.stepInstallmentSlider);
        });

        /**
         * locationData must have the following props:
         * locationData.appVersion;
         * locationData.publicIp;
         * locationData.latitude;
         * locationData.longitude;
         * locationData.timestamp;
         * @param locationData
         * @returns {{appVersion: (*|string), coord: {latitude: (*|string), longitude: (*|string), timestamp: (*|string)}, public_ip: (*|string), _type: string}}
         */
        this.getDataGeolocation = function(locationData){
            return {
                _type: this.geolocation(),
                appVersion: locationData.appVersion?locationData.appVersion:'',
                public_ip: locationData.publicIp?locationData.publicIp:'',
                coordList: [{
                    latitude: locationData.latitude ? locationData.latitude : '',
                    longitude: locationData.longitude ? locationData.longitude : '',
                    timestamp: locationData.timestamp ? locationData.timestamp : ''
                }],
            };
        }

        /**
         * addressData must have the following props:
         * addressData.street_name;
         * addressData.street_number;
         * addressData.zipcode;
         * addressData.telephone;
         * addressData.city;
         * addressData.province;
         * @param addressData
         * @returns {{numero: (string|*), calle: (string|*), _type: string,
         *          provinciaCodigo: (Number|*),
         *          provincia: (String|*),
         *          localidad: (string|*), codPostal: (string|*), telefonoFijo: (*|string)}}
         */
        this.getDataAddress = function(addressData){
            return {
                _type: this.address(),
                calle: addressData.street_name?addressData.street_name:'',
                numero: addressData.street_number?addressData.street_number:'',
                codPostal: addressData.zipcode?addressData.zipcode:'',
                telefonoFijo: addressData.telephone?addressData.telephone:'',
                localidad: addressData.city?addressData.city:'',
                provinciaCodigo: addressData.province?addressData.province:'',
                provincia: addressData.provinceName?addressData.provinceName:'',
                piso: addressData.floor?addressData.floor:'',
                departamento: addressData.apartment_number?addressData.apartment_number:''
            }
        }

        /**
         *
         * @param dataForm
         * @returns {{cbu: (string|*), _type: string, banco: (null|*)}}
         */
        this.getDataCbuBank = function (dataForm) {
            return {
                _type: this.creditApproval(),
                cbu: dataForm.cbu,
                banco: dataForm.bank
            }
        }


        /* Creating a function that returns a form. */
        this.getDataContract = function (dataForm) {
            return {
                _type: this.acceptLoanContract(),
                aceptacredito: true,
                tyc: dataForm.tyc
            }
        }

        this.getNewCreditForm = function() {
            return {
                _type: this.newCreditType(),
            }
        }
    }

    /**
     *
     * @param headersOption
     * @returns {{headers: (*&{Authorization: (*|string)})}}
     */
    getRequestOptions(headersOption) {
        this.authToken = this.authToken?this.authToken:
                    axios.defaults.headers.common["Authorization"];
        let headersReq = {
                accept: 'application/json',
                'Authorization': this.authToken,
                ...headersOption
        };

        return {
            headers: headersReq
        };
    }

    /**
     * get data from dni
     * @param dni
     * @returns {PromiseLike<any> | Promise<any>}
     */
    validateData(dni) {
        const url = `${this.routes.BCRA}/${dni}`;
        return this.sendRequest(url, 'GET').then((data) => data);
    }

    /**
     * build request to send
     * @param url
     * @param methodRequest
     * @param dataInput
     * @param additionalHeaders
     * @returns {Promise<T>}
     */
    sendRequest(url, methodRequest, dataInput = null, additionalHeaders = {}) {
        const requestOptions = this.getRequestOptions(additionalHeaders);
        const dataForm = dataInput ? JSON.stringify(dataInput) : null;
        const promise = axios({baseURL: process.env.VUE_APP_URL+'/registro/', url: url, method: methodRequest, data: dataForm, ...requestOptions})
        return promise.then((response) => response.data);
    }

    /**
     *
     * @param user
     * @param pass
     */
    setToken(user, pass) {
        let token = `${user}:${pass}`;
        let encodedToken = btoa(token);
        const tokens_trim = encodedToken.trim().replace("\n", "");
        this.authToken = "Basic " + tokens_trim;
        return this.authToken
    }

    /**
     *
     * @param tkfb
     * @param id
     * @returns {PromiseLike<any> | Promise<any>}
     */
    loginServer(tkfb, id, tk) {
        let body = {
            fcmToken: id,
            facebookId: tkfb,
            legacyFbkId: tkfb
        };
        let head = {
            os: 'web',
            'X-Findo-Authorization' : tk
        }
        const url = `${this.routes.SINGUP}`;
        return axios.post(url, body, {headers: head}).then((res) => {
                if (res.data.status !== 200) {
                    throw new Error(res.message);
                }
                return this.setToken(tkfb, res.data.message);
            }
        );
    }


    /**
     * Check if dni get a loan
     * @param {*} dni
     * @returns
     */
    isClientEnableForLoan(dni) {
        const rndInt = Math.floor(Math.random() * 2) + 1;
        return rndInt === 1;
    }

    /**
     * Check validation of cbu
     * @param {*} cbu
     * @returns
     */
    isValidCbu(cbu) {
        const rndInt = Math.floor(Math.random() * 2) + 1;
        return rndInt === 1;
    }

    /**
     * create a loan on api, where cbu is account destiny of loan and infoCard is card where debit loan
     * @param {*} user
     * @param {*} cbu
     * @param {*} infoCard
     * @returns
     */
    createLoan(user, cbu, infoCard) {
        return true;
    }

    testApi() {
        return "chau";
    }

    /**
     * send messagge to phone number with a code
     * request to have phoneNumber with code country and code area
     * @param phoneNumber
     * @returns {Promise<T>}
     */
    sendMessagePhoneCode(phoneNumber) {
        const url = `${this.routes.SEND_SMS}?phone=${phoneNumber}`;
        return this.sendRequest(url, 'post', null).then((data) => data);
    }

    /**
     * send phone Number and code (recevied on cellphone) to check the cellphone
     * @param phoneNumber
     * @param code
     * @returns {Promise<T>}
     */
    validatePhoneCode(phoneNumber, code) {
        const url = `${this.routes.VALIDATE_PHONE}?phone=${phoneNumber}&&code=${code}`;
        return this.sendRequest(url, 'post').then((data) => data);
    }

    /**
     * check if number can get a loan
     * @param number
     * @returns {Promise<T>}
     */
    checkCredit(number) {
        const url = `${this.routes.VALIDATE}/${number}`;
        return this.sendRequest(url, 'get').then((data) => data);
    }

    /**
     *
     * @param anInstallment
     * @param anAmount
     * @returns {Promise<{firstPaymentCuttingDay: *, monthlyExpirationDay: *, plans: {"1": null, "2": null, "3": null},
     * credit: *}>}
     */
    getCredits(anInstallment, anAmount) {
        const url = `${this.routes.CREDIT_CONFIG}?branch=presterWeb`;
        return this.sendRequest(url, 'get').then((res) => {
            if (!res.hasOwnProperty('creditConfig')) {
                throw new Error(res.message);
            }
            let creditsOption = res.creditConfig.precomputedValues;
            let plans = this.getPlans(creditsOption, anInstallment, anAmount);
            return {
                'plans': plans,
                'monthlyExpirationDay': res.creditConfig.monthlyExpirationDay,
                'firstPaymentCuttingDay': res.creditConfig.firstPaymentCuttingDay,
                'credit': res.creditConfig.id,
                'interestRate': res.creditConfig.interestRate,
            };
        });
    }

    /**
     *
     * @param creditsOption
     * @param anInstallment
     * @param anAmount
     * @returns {{"1": null, "2": null, "3": null}}
     */
    getPlans(creditsOption, anInstallment, anAmount) {
        let credits = {1: null, 2: null, 3: null};
        let previous = anInstallment - this.#step;
        let subsequent = anInstallment + this.#step;
        creditsOption.forEach((item) => {
            if (item.amount !== anAmount) {
                return;
            }
            let isPrevious = previous >= this.#min && item.installments === previous;
            let isSelected = item.installments === anInstallment;
            let isSubsequent = subsequent <= this.#max && item.installments === subsequent;
            if (isPrevious) {
                credits[1] = item;
            }
            if (isSelected) {
                credits[2] = item;
            }
            if (isSubsequent) {
                credits[3] = item;
            }
        });

        return credits;
    }

    /**
     *
     * @param picture as File
     * @param type
     * @returns {Promise<T>}
     */
    savePicture(picture, type) {
        const url = `${this.routes.IMAGE}`;
        let head = {
            'Authorization': this.authToken,
            "Content-Type": "multipart/form-data"
        };
        let dataBody = new FormData();
        dataBody.append('image', picture);
        dataBody.append('attachmentType', type);
        return axios({baseURL: process.env.VUE_APP_URL+'/registro/',url: url, method: 'post', headers: head, data: dataBody}).then((data) => data);
    }

    /**
     * Use returned value for attachment picture
     * @returns {string}
     */
    back(){
        return 'DNI_BACK';
    }

    /**
     *Use returned value for attachment picture
     * @returns {string}
     */
    front() {
        return 'DNI_FRONT';
    }

    /**
     * Use returned value for attachment picture
     * @returns {string}
     */
    selfie() {
        return 'SELFIE';
    }

    /**
     * Use returned value for form name
     * @returns {string}
     */
    geolocation() {
        return 'Location';
    }

    /**
     * Use returned value for form name
     * @returns {string}
     */
    address() {
        return 'Address';
    }

    /**
     * type must be formName
     * @param info
     * @param type
     * @returns {{data: string, formName: string}}
     */
    getDataForm(info, type) {
        let dataForm = {
                formName: 'UserForm',
                data: ''
            };
        switch (type) {
            case this.address():
                dataForm.data = this.getDataAddress(info);
                break;
            case this.geolocation():
                dataForm.formName = this.geolocation();
                dataForm.data = this.getDataGeolocation(info);
                break;
            case this.creditApproval():
                dataForm.data = this.getDataCbuBank(info);
                break;
            case this.acceptLoanContract():
                dataForm.data = this.getDataContract(info);
                break;
            case this.newCreditType():
                dataForm.data = this.getNewCreditForm();
                break;
            default:
                dataForm.formName = '';
        }
        return dataForm;
    }

    /**
     * reset token
     */
    reset() {
      this.authToken = null;
    }

    /**
     * save payment and credit to current user
     * @param {{monthlyExpirationDay: string, credit: string}} infoCredit
     * @param {{amount: null, installments: null,
     *          credit: {
     *              amountIva: null,
     *              cft: null, tea: null,
     *              amountAdminCost: null,
     *              amountInterests: null,
     *              avgInstallment: null,
     *              amountInsurance: null, amountStampTax: null}}} infoPayment
     * @returns {Promise<T>}
     */
    saveCredit(infoCredit, infoPayment) {
        const url = `${this.routes.CREDIT_REQUIRE}`;
        if(!this.validaDataCredit(infoCredit, infoPayment)){throw new Error("Error: credit is undefined")}
        let data = this.setParamCredit(infoCredit, infoPayment);
        let additionalHeader = {'Content-Type': 'application/json', 'os': 'web'};
        return this.sendRequest(url, 'post', data, additionalHeader)
            .then((data) => data);
    }

    /**
     * return body to send for saveCredit
     * @param {{monthlyExpirationDay: string, credit: string}} infoCredit
     * @param {{amount: null, installments: null, credit: {
     *           amountIva: null,
     *           cft: null,
     *           tea: null,
     *           amountAdminCost: null,
     *           amountInterests: null,
     *           avgInstallment: null,
     *           amountInsurance: null, amountStampTax: null}}} infoPayment
     * @returns {any}
     */
    setParamCredit(infoCredit, infoPayment) {
        return {
            creditConfigId: infoCredit.credit,
            creditAmount: infoPayment.amount,
            interestRate: infoPayment.credit.amountInterests,
            adminCost: infoPayment.credit.amountAdminCost,
            iva: infoPayment.credit.amountIva,
            insurance: infoPayment.credit.amountInsurance,
            totalInstallments: infoPayment.installments,
            preferredPaymentDay: infoCredit.monthlyExpirationDay,
            avgInstallmentAmount: infoPayment.credit.avgInstallment,
            destination: "",
            comments: "",
            productDescription: "",
            customCode: "",
            vendorCode: ""
        }
    }

    /**
     *  check has attributes
     * @param infoCredit
     * @param infoPayment
     * @returns {boolean}
     */
    validaDataCredit(infoCredit, infoPayment) {
        return !!infoCredit.credit && !!infoPayment.amount && !!infoPayment.installments;
	}

	/**
     *
     * @returns {string}
     */
    creditApproval() {
        return "CreditApproval_3";
    }

    /**
     * The function accepts a loan contract and returns a credit approval
     * @returns The name of the form.
     */
    acceptLoanContract() {
        return "CreditApproval_FINDO_1";
    }

    /**
     *
     * @param dataForm
     * @param type
     * @returns {Promise<T>}
     */
    sendFormData(dataForm, type) {
        const url = `${this.routes.CREATE_FORM}`;
        let data = this.getDataForm(dataForm, type);
        let additionalHeader = {'Content-Type': 'application/json'};
        return this.sendRequest(url, 'post', data, additionalHeader)
            .then((data) => data);
    }

    /**
     *
     * @param userForm
     * @returns {Promise<T>}
     */
    sendFormHelpData(userForm) {
      const url = `${this.routes.SEND_QUERIES}`;
      let auth = {
        'username': process.env.VUE_APP_USER_FORM_HELP,
        'password': process.env.VUE_APP_PASS_FORM_HELP
      }
      let head = {
        "Content-Type": "application/json"
      };
      let dataBody = this.setHelpFormUser(userForm);
      return axios({baseURL: process.env.VUE_APP_URL+'/registro/',url: url, method: 'post', headers: head, data: dataBody, auth: auth}).then((data) => data);
    }

  /**
     *
     * @param userForm
     * @returns {{phone: (string|*), lastName: (string|*), comments: (string|*),
   *          name: (*|string), email: (*|string)}}
   */
    setHelpFormUser(userForm) {
      return {
          lastName: userForm.lastName ? userForm.lastName : "",
          name: userForm.name ? userForm.name : "",
          email: userForm.email ? userForm.email : "",
          phone: userForm.phone ? userForm.phone : "",
          comments: userForm.comments ? userForm.comments : ""
      };
    }

    /**
     *
     * @param loanId
     * @returns {Promise<T>}
     */
    finishLoanRequest(loanId) {
        const url = `${this.routes.FINISH_CREDIT}/${loanId}`;
        let additionalHeader = {'Content-Type': 'application/json'};
        return this.sendRequest(url, 'post', null, additionalHeader)
            .then((data) => data);
    }

    /**
     * Return url to mobbex form.
     * @returns {Promise<T>}
     */
    getFormInfoCard() {
        const url = `${this.routes.PAYMENT_REGISTRATION}`;
        return this.sendRequest(url, 'get')
            .then((data) => data);
    }

    /**
    * @param userForm
    * @returns {Promise<T>}
    */
    setDataUser(userForm) {
        const url = `${this.routes.SET_DATA}`;
        let data = this.setFormUser(userForm);
        let additionalHeader = {'Content-Type': 'application/json'};
        return this.sendRequest(url, 'post', data, additionalHeader)
            .then((data) => data);
    }

    /**
     *
     * @param userForm
     * @returns {{birthday: (string|*), firstName: (string|*), lastName: (string|*), cuit: (string|*),
     *          nationality: (string|*), extraData: *, sex: (string|*), nationalityId: (number|*),
     *          name: (*|string), lastName2: (string|*), dni: (string|*), email: (*|string)}}
     */
    setFormUser(userForm) {
        return {
            dni: userForm.dni ? userForm.dni : "",
            cuit: userForm.cuit ? userForm.cuit : "",
            firstName: userForm.firstName ? userForm.firstName : "",
            lastName: userForm.lastName ? userForm.lastName : "",
            lastName2: userForm.lastName2 ? userForm.lastName2 : "",
            name: userForm.name ? userForm.name : "",
            sex: userForm.sex ? userForm.sex : "",
            email: userForm.email ? userForm.email : "",
            birthday: userForm.birthday ? userForm.birthday : "01/01/2021",
            nationality: userForm.nationality ? userForm.nationality : "",
            nationalityId: userForm.nationalityId ? userForm.nationalityId : 0,
            extraData: userForm.extraData ? userForm.extraData : {}
        };
    }

    /**
     * It sends a POST request to the server with the dataForm object, the fcm token and the fbkId
     * @param dataForm - is the data that we get from Facebook.
     * @param fcm - Firebase Cloud Messaging token
     * @param fbkId - Facebook ID
     * @returns The data is being returned.
     */
    sendFormLoginUser(dataForm, fcm, fbkId) {
        const url = `${this.routes.CREATE_FORM}`;
        let data = {
            formName: "Facebook",
            data: {

                _type: "Facebook",
                fbk:
                    {
                        user:
                            {
                                id: '',
                                first_name: '',
                                last_name: '',
                                name: dataForm.name,
                                gender: '',
                                email: dataForm.email,
                                timezone: '',
                                fcmToken: fcm,
                                verified: ''
                            },
                        legacyFbkId: fbkId,
                        friends_quantity: '',
                        email: '',
                        appVersion: ''
                    }
            }
        }
        let additionalHeader = {'Content-Type': 'application/json'};
        return this.sendRequest(url, 'post', data, additionalHeader)
            .then((data) => data);
    }

    /**
     * It makes a GET request to the URL `${this._baseUrl}/payment/requestPaymentRegistrationEndpoint` and returns a
     * promise that resolves to an object containing the subscription and subscriber IDs
     * @returns The subscription and subscriber ID's are being returned.
     */
    getPaymentModal() {
        const url = `${this.routes.PAYMENT_REGISTRATION}`;
        let additionalHeader = {'Content-Type': 'application/json'};
        return this.sendRequest(url, 'get', null, additionalHeader)
            .then((data) => {return {'subscription': data.additionalInfo.subscriptionId, 'subscriber': data.additionalInfo.subscriberId}});
    }

    /**
     * If the authToken is not empty, null, or undefined, then the user is logged in
     * @returns A boolean value.
     */
    isLogin() {
        return this.authToken !== "" && this.authToken !== null && this.authToken !== undefined;
    }

    /**
     * It sends a GET request to the server to check if the user is already registered
     * @returns A promise that resolves to the data returned from the server.
     */
    isRegistered() {
        const url = `${this.routes.ALREADY_REGISTERED}`;
        return this.sendRequest(url, 'get', null)
            .then((data) => data);
    }

    /**
     * It makes a GET request to the server and returns the data
     * @returns The data from the API call.
     */
    getStatusLastLoan() {
        const url = `${this.routes.STATUS_LAST_LOAN}`;
        return this.sendRequest(url, 'get', null)
            .then((data) => data);
	  }

    /**
     *It makes a GET request to the URL `${this._baseUrl}/personalCredit/list` and returns a
     * promise that resolves to an object containing all user credits.
     * @returns All user credits are being returned.
     */
    listAllPersonalCredits() {
      const url = `${this.routes.LIST_ALL_PERSONAL_CREDITS}`;
      let additionalHeader = {'Content-Type': 'application/json'};
      return this.sendRequest(url, 'get', null, additionalHeader)
          .then((data) => data);
    }

    /**
     * It sends a GET request to the server and returns the data
     * @param loanId - The loan id of the loan you want to get the credit conditions for.
     * @returns a promise.
     */
    getCreditContidion(loanId) {
        const url = `${this.routes.GET_CONDITIONS_CREDIT}/${loanId}`;
        let additionalHeader = {'Content-Type': 'application/json'};
        return this.sendRequest(url, 'get', null, additionalHeader)
            .then((data) => data);
	}

    /**
     * It makes a GET request to the server and returns the response
     * @returns The response from the server.
     */
    getCreditUser() {
        const url = `${this.routes.CREDIT_CONFIG}`;
        return this.sendRequest(url, 'get').then((res) => {
            if(!res.hasOwnProperty('creditConfig')) {
                throw Error("not-credit-config");
            }
            return this.reformatOptions(res.creditConfig);
        });
    }

    /**
     * It takes a JSON object with a bunch of precomputed values and reformats it into a JSON object that is easier to use
     * in the front end
     * @param data - the data returned from the API
     * @returns An object with the following properties:
     * - creditAmountMin
     * - creditAmountMax
     * - creditAmountStep
     * - totalInstallmentsMin
     * - totalInstallmentsMax
     * - totalInstallmentsStep
     * - interestRate
     * - options
     */
    reformatOptions(data) {
        let result = {};
        result.creditAmountMin = data.creditAmountMin;
        result.creditAmountMax = data.creditAmountMax;
        result.creditAmountStep = data.creditAmountStep;
        result.totalInstallmentsMin = data.totalInstallmentsMin;
        result.totalInstallmentsMax = data.totalInstallmentsMax;
        result.totalInstallmentsStep = data.totalInstallmentsStep;
        result.interestRate = data.interestRate;
        result.needSelfie = data.needSelfiePhoto;
        result.needDni = data.needDNIPhoto;
        result.firstPaymentCuttingDay = data.firstPaymentCuttingDay;
        result.configCredit = data.id;
        result.monthlyExpirationDay = data.monthlyExpirationDay;
        result.options= {};
        let maxInstallments = data.totalInstallmentsMax+1;
        for (var i = data.totalInstallmentsMin; i < maxInstallments; i++) {
            result.options[i] = {};
        }
        data.precomputedValues.forEach((item) => {
            result.options[item.installments][item.amount] = item;
        })
        return result;
    }

    /**
     * It makes a GET request to the URL `${this._baseUrl}/personalCredit/myAccount` and returns
     * a promise that resolves to an object containing the field that determinate
     * if the user can get a new credit.
     * @returns the field that determinate if the user can get a new credit are being returned
     */
    getNewCredit() {
      const url = `${this.routes.GET_NEW_CREDIT}`;
      let additionalHeader = {'Content-Type': 'application/json'};
      return this.sendRequest(url, 'get', null, additionalHeader)
          .then((data) => {
              return data
          });
    }

    /**
     * It gets the user's information from the server
     * @returns An object with the user's full name, first name, cuit, and phone number.
     */
    getInfoUser() {
        const url = `${this.routes.GET_USER_INFO}?types=UserForm`;
        let additionalHeader = {'Content-Type': 'application/json'};
        return this.sendRequest(url, 'get', null, additionalHeader)
            .then((data) => {
                let info = data[0];
                return {fullname:info.user.lastName+' '+info.user.firstName, name:info.user.firstName,
                    cuit: info.user.bureau.cuit, phone: info.user.phone.number, dni: info.user.dni, birthday: info.user.birthday,
                    lastname: info.user.lastName};
            });
    }

    /**
     * It takes a string or an array of strings as an argument, and returns a promise that resolves to an object containing
     * the user's data
     * @param types - A string or an array of strings that represent the types of data you want to retrieve.
     * @returns The data is being returned.
     */
    getUserData(types) {
      const typeofTypes = typeof types;
      if (typeofTypes !== 'string' && !Array.isArray(types)) {
        throw new Error(`listPersonalData: invalid type ${typeofTypes} for the argument "types"`);
      }
      const parsedTypes = typeofTypes === 'string' ? types : types.join(',');
      const url = `${this.routes.GET_USER_DATA}`;
      let additionalHeader = {'Content-Type': 'application/json'};
      return this.sendRequest(url + `?types=${parsedTypes}`, 'get', null, additionalHeader)
          .then((data) => data);
    }


    /**
     * It sends a post request to the server with the phone number as a parameter
     * @param phone - The phone number to save.
     * @returns The promise is being returned.
     */
    savePhone(phone) {
        const url = `${this.routes.SAVE_PHONE}?phone=${phone}`;
        let additionalHeader = {'Content-Type': 'application/json'};
        return this.sendRequest(url, 'post', null, additionalHeader)
            .then((data) => {
                return data
            });
    }

    /**
     * It makes a GET request to the server, and returns the data it receives
     * @returns The data is being returned.
     */
    updateConditionsLoans() {
        const url = `${this.routes.UPDATE_CONDITIONS_LOANS}`;
        let additionalHeader = {'Content-Type': 'application/json'};
        return this.sendRequest(url, 'get', null, additionalHeader)
            .then((data) => {
                return data;
            });
    }

    newCreditType() {
        return "NewCredit";
    }

    setInfoUser(userForm) {
        const url = `${this.routes.SET_INFO_USER}`;
        let data = this.setFormUser(userForm);
        let additionalHeader = {'Content-Type': 'application/json'};
        return this.sendRequest(url, 'post', data, additionalHeader)
            .then((data) => data);
    }

    /**
     *
     * @param userForm
     * @returns {Promise<T>}
     */
    getGeneralRates() {
        const url = `${this.routes.GENERAL_RATES}`;
        let auth = {
            'username': process.env.VUE_APP_USER_FORM_HELP,
            'password': process.env.VUE_APP_PASS_FORM_HELP
        }
        let head = {
            "Content-Type": "application/json"
        };
        return axios({baseURL: process.env.VUE_APP_URL+'/registro/',url: url, method: 'get', headers: head, auth: auth}).then((data) => data);
    }
}