<template>
  <v-dialog
    v-model="loader"
    persistent
    width="500"
  >
    <v-card>
      <div
        align="center"
        class="loader--img"
      >
        <v-img
          src="@/assets/img/yellow_pet_glass.svg"
          alt="logo"
          max-width="30%"
          contain
        >
        </v-img>
      </div>
      <div style="height: 100px; margin-top: 20px">
        <div align="center" justify="center">
        <h4>
          {{ $store.state.loaderMsg }}
        </h4>
        </div>
        <div
          class="ml-5 mr-5"
        >
          <v-progress-linear
            indeterminate
            height="10"
            style="margin-top:-10px"
            color="yellow darken-2"
          />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LoadingDialog",
  methods: {},
  computed: {
    ...mapGetters(["getLoader"]),
    loader() {
      return this.getLoader;
    },
  },
};
</script>
