var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showMyLoans)?_c('div',[_c('h3',{staticClass:"textAlignLeft"},[_vm._v(" Mis préstamos ")]),(_vm.pendingCreditsItems.length > 0)?_c('div',{attrs:{"id":"pending"}},[_c('h5',{staticClass:"mb-2 textAlignLeft"},[_vm._v(" Pendientes ")]),_c('div',{staticClass:"home-user__tables--background",attrs:{"id":"pending-credits"}},[_c('v-data-table',{staticClass:"home-user__tables home-user__tables-detail--text",attrs:{"headers":_vm.pendingCredits,"items":_vm.pendingCreditsItems,"header-props":{
          'sort-by-text':'Ordenar por'
        },"hide-default-footer":"","mobile-breakpoint":"0","disable-sort":""}})],1)]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeCreditsItems.length > 0),expression:"activeCreditsItems.length > 0"}],attrs:{"id":"active"}},[_c('h5',{staticClass:"mb-2 textAlignLeft"},[_vm._v(" Activos ")]),_c('div',{staticClass:"loans-active-credits home-user__tables--background",attrs:{"id":"active-credits"}},[_c('v-data-table',{staticClass:"home-user__tables home-user__tables-detail--text",attrs:{"headers":_vm.activeCredits,"items":_vm.activeCreditsItems,"header-props":{
          'sort-by-text':'Ordenar por'
        },"hide-default-footer":"","mobile-breakpoint":"0","disable-sort":""},on:{"click:row":_vm.openMoreInfo},scopedSlots:_vm._u([{key:"item.creationDate",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.creationDate))]),_c('v-icon',{staticClass:"detail-loan-dialog__icons-detail",attrs:{"color":"#AA78FF","title":"detalles préstamo"}},[_vm._v(" mdi-plus-circle ")])]}}],null,false,3035355528)})],1)]),(_vm.previousCreditsItems.length > 0)?_c('div',{attrs:{"id":"previous"}},[_c('h5',{staticClass:"mb-2 textAlignLeft"},[_vm._v(" Previos ")]),_c('div',{staticClass:"home-user__tables--background",attrs:{"id":"previous-credits"}},[_c('v-data-table',{staticClass:"home-user__tables home-user__tables-detail--text",attrs:{"headers":_vm.previousCredits,"items":_vm.previousCreditsItems,"items-per-page":5,"header-props":{
          'sort-by-text':'Ordenar por'
        },"hide-default-footer":"","mobile-breakpoint":"0","disable-sort":""}})],1)]):_vm._e(),_c('detail-loan-dialog',{attrs:{"loan":_vm.itemSelected},model:{value:(_vm.info),callback:function ($$v) {_vm.info=$$v},expression:"info"}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }