export default class Person {
    _dni = null
    _phone = null
    _cuit = null
    _fullname=null
    _name=null
    _birthday=null
    _lastname=null
    /**
     *
     * @param fullname
     * @param dni
     * @param phone
     * @param cuit
     * @param name
     * @param birthday
     */
    constructor(fullname, dni, cuit, phone, name, birthday, lastname) {
        this._dni = dni;
        this._phone = phone;
        this._cuit = cuit;
        this._fullname = fullname;
        this._name = name;
        this._birthday = birthday;
        this._lastname = lastname;
    }


    getFullname() {
        return this._fullname;
    }

    getDni() {
        return this._dni;
    }

    getCuit() {
        return this._cuit;
    }

    getPhone() {
        return this._phone;
    }

    get name() {
        return this._name;
    }
}