<template>
  <div>
    <div>
      <v-row align="center" justify="center">
        <div class="col-md-4 col-xs-12" align="center" justify="center">
          <v-img
            class="mx-auto"
            :src="img_status"
            alt="Mascota Estado"
            position="center center"
          />
        </div>

        <div class="col-md-8 col-xs-12" align="center" justify="center">
          <h2 class="darkPurple">{{ title_status }}</h2>
          <h1 class="stepLoan--textImg">{{ subtitle_status }}</h1>
          <div v-html="description_status" />
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
import petGlasses from "@/assets/img/pet_glasses_2.svg";
import prestercito_lupa from "@/assets/img/prestercito_lupa.svg";
import prestercito_de_cabeza from "@/assets/img/prestercito_de_cabeza.svg";
import pet_happy_2 from "@/assets/img/pet_happy_2.svg";
import petOk from "@/assets/img/pet_ok.png";
import petHappy from "@/assets/img/pet_happy.png";
import { mapActions } from "vuex";
export default {
  name: "StatusLoan",
  data: () => {
    return {
      status_image: {
        APPROVED: {
          img: prestercito_lupa,
          title: "",
          subtitle: "¡Recibimos tu solicitud de préstamo!",
          description:
            "Estamos <b>verificando la información y si cumples con los requisitos</b> recibirás el dinero dentro de las proximas 24 horas",
        },
        ACCEPTED: {
          img: prestercito_de_cabeza,
          title: "¡Felicidades!",
          subtitle: "Tu préstamo fue aprobado",
          description:
            "En breve <b>recibirás el dinero</b> en tu cuenta y un <b>email de confirmación de deposito.</b>",
        },
        OPENED: {
          img: pet_happy_2,
          title: "¡Felicidades!",
          subtitle: "Tu préstamo fue depositado",
          description:
            "Te confirmamos que el <b>dinero fue depositado en tu cuenta</b> y te hemos enviado un email con los detalles.",
        },
        PREAPPROVED: {
          img: prestercito_lupa,
          title: "",

          subtitle: "¡Recibimos tu solicitud de préstamo!",
          description:
            "Estamos <b>verificando la información y si cumples con los requisitos</b> recibirás el dinero dentro de las proximas 24 horas",
        },
      },
      default_status: {
        img: petGlasses,
        title: "",
        subtitle: "Actualmente no tenés ninguna solicitud en proceso.",
        description: "",
      },
      img_status: "",
      title_status: "",
      subtitle_status: "",
      description_status: "",
      fitImage: true,
    };
  },
  mounted() {
    this.openLoader();
    this.$apiserver
      .getStatusLastLoan()
      .then((result) => {
        if (!result.hasOwnProperty("status")) {
          throw new Error("status-not-defined");
        }
        const status = result.status;
        this.img_status = this.default_status.img;
        this.title_status = this.default_status.title;
        this.subtitle_status = this.default_status.subtitle;
        this.description_status = this.default_status.description;
        if (this.status_image.hasOwnProperty(status)) {
          const { [status]: state } = this.status_image;
          this.img_status = state.img;
          this.title_status = state.title;
          this.subtitle_status = state.subtitle;
          this.description_status = state.description;
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.closeLoader();
      });
  },
  methods: {
    ...mapActions(["openLoader", "closeLoader"]),
  },
};
</script>

<style scoped>
</style>