<template>
  <div v-if="showMyLoans">
    <h3 class="textAlignLeft">
      Mis préstamos
    </h3>

    <div
      v-if="pendingCreditsItems.length > 0"
      id="pending"
    >
      <h5 class="mb-2 textAlignLeft">
        Pendientes
      </h5>

      <div id="pending-credits" class="home-user__tables--background">
        <v-data-table
          :headers="pendingCredits"
          :items="pendingCreditsItems"
          :header-props="{
            'sort-by-text':'Ordenar por'
          }"
          class="home-user__tables home-user__tables-detail--text"
          hide-default-footer
          mobile-breakpoint="0"
          disable-sort
        />
      </div>
    </div>

    <div
      v-show="activeCreditsItems.length > 0"
      id="active"
    >
      <h5 class="mb-2 textAlignLeft">
        Activos
      </h5>

      <div
        id="active-credits"
        class="loans-active-credits home-user__tables--background"
      >
        <v-data-table
          :headers="activeCredits"
          :items="activeCreditsItems"
          :header-props="{
            'sort-by-text':'Ordenar por'
          }"
          class="home-user__tables home-user__tables-detail--text"
          hide-default-footer
          mobile-breakpoint="0"
          disable-sort
          @click:row="openMoreInfo"
        >
          <template #item.creationDate="{ item }">
            <span>{{ item.creationDate }}</span>
            <v-icon
              class="detail-loan-dialog__icons-detail"
              color="#AA78FF"
              title="detalles préstamo"
            >
              mdi-plus-circle
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </div>

    <div
      v-if="previousCreditsItems.length > 0"
      id="previous"
    >
      <h5 class="mb-2 textAlignLeft">
        Previos
      </h5>

      <div id="previous-credits" class="home-user__tables--background">
        <v-data-table
          :headers="previousCredits"
          :items="previousCreditsItems"
          :items-per-page="5"
          :header-props="{
            'sort-by-text':'Ordenar por'
          }"
          class="home-user__tables home-user__tables-detail--text"
          hide-default-footer
          mobile-breakpoint="0"
          disable-sort
        />
      </div>
    </div>
    <detail-loan-dialog
      v-model="info"
      :loan="itemSelected"
    />
  </div>
</template>

<script>
import { creditStatus } from '@/helper/creditStatus'
import moment from 'moment'
import { creditStatusLabels } from '@/helper/creditStatusLabels'
import { mapActions } from "vuex";
import { mdiLoupe } from '@mdi/js';
import DetailLoanDialog from "@/components/manage_user/DetailLoanDialog";


export default {
  name: "MyLoans",
  components: {
    DetailLoanDialog
  },
  props: {
  },
  data: () => ({
    pendingCredits: [
      { text: 'Fecha', value: 'creationDate' },
      { text: 'Monto', value: 'approvedAmount' },
      { text: 'Cuotas', value: 'totalInstallments' }
    ],
    pendingCreditsItems: [],
    activeCredits: [
      { text: 'Fecha', value: 'creationDate' },
      { text: 'Monto', value: 'approvedAmount' },
      { text: 'Cuotas', value: 'totalInstallments' }
    ],
    activeCreditsItems: [],
    previousCredits: [
      { text: 'Fecha', value: 'creationDate' },
      { text: 'Monto', value: 'approvedAmount' },
      { text: 'Estado', value: 'statusFormatted' }
    ],
    previousCreditsItems: [],
    showMyLoans: false,
    info:false,
    itemSelected: {
      loan: null,
      dateActive: null,
      status: null,
      installments_paid: null,
      installments_delayed: null,
      installment_to_pay: null,
      installment_amount: null,
      last_payment: null,
      next_expiry_date: null
    },
    zoom: mdiLoupe
  }),
  mounted() {
    this.listAllPersonalCreditsMethod();
  },
  methods: {
    ...mapActions(["openLoader", "closeLoader"]),
    listAllPersonalCreditsMethod() {
      const pendingStatus = [
      creditStatus.PENDING,
      creditStatus.APPROVED,
      creditStatus.DOCUMENTING,
      creditStatus.ACCEPTED
    ];

    const activeStatus = [
      creditStatus.OPENED,
      creditStatus.DELAYED
    ];

    const previousStatus = [
      creditStatus.CLOSED,
      creditStatus.REJECTED,
      creditStatus.COMPLETED,
      creditStatus.BAD
    ];

    this.openLoader();

    this.$apiserver
        .listAllPersonalCredits()
        .then((data) => {
          data.forEach((credit) => {
            credit.creationDate = moment(Number(credit.creationDate)).format('DD/MM/YYYY');
            if(credit.approvedAmount === undefined) {
              credit.approvedAmount = 'No definido';
            } else {
              credit.approvedAmount = '$ ' + Number(credit.approvedAmount).toLocaleString("es-AR");
            }
            credit.creditAmount = '$ ' + Number(credit.creditAmount).toLocaleString("es-AR");
            credit.statusFormatted = (creditStatusLabels[credit.status] || '');
          })
          this.pendingCreditsItems = data.filter(c => pendingStatus.includes(c.status));
          this.activeCreditsItems = data.filter(c => activeStatus.includes(c.status));
          this.previousCreditsItems = data.filter(c => previousStatus.includes(c.status));
          this.showMyLoans = true;
        })
        .catch((e) => {
          this.$store.commit("ERROR_MODAL", true);
          console.log(e);
          this.error_identity = true;
          console.log("No pudimos obtener tus datos");
          this.openLoader("¡Ups! Tuvimos un inconveniente. Por favor, inténtalo más tarde.");
        })
        .finally(() => {
          this.closeLoader();
        });
  },
    openMoreInfo(item) {
        this.$apiserver.getCreditContidion(item.id)
            .then((condition) => {
              this.itemSelected.loan = item.id;
              this.itemSelected.dateActive = item.creationDate;
              this.itemSelected.status = item.statusFormatted;
              this.itemSelected.installments_paid =
                  condition.hasOwnProperty('installmentsPaid')?
                      condition.installmentsPaid:'-';
              this.itemSelected.installments_delayed =
                  condition.hasOwnProperty('installmentsDelayed')?
                      condition.installmentsDelayed:'-';
              this.itemSelected.installments_to_pay =
                  condition.hasOwnProperty('installmentsRemaining')?
                      condition.installmentsRemaining:'-';
              this.itemSelected.installment_amount =
                  condition.hasOwnProperty('installmentAmount')?
                      condition.installmentAmount:'-';
              this.itemSelected.last_payment =
                  condition.hasOwnProperty('lastPaymentDate')?
                      moment.unix(condition.lastPaymentDate).format("DD/MM/YYYY"):'-';
              this.itemSelected.next_expiry_date =
                  condition.hasOwnProperty('nextDueDate')?
                      moment.unix(condition.nextDueDate).format("DD/MM/YYYY"):'-';
              this.info=true;
            })
    }
  },
};
</script>

