<template>
  <div>
    <v-app class="bodyHomeYellow">
      <v-toolbar
        class="solicitud__header"
        elevation="0"
      >
        <v-flex class="step__header-main-image">
          <v-img
            class="home__main-logo"
            src="@/assets/logo.svg"
            alt="logo"
            @click="$router.replace({ name: 'home' })"
          />
        </v-flex>
        <v-flex>
          <h1 class="text-right textHeaderDesktop">
            Yo te presto.
          </h1>
        </v-flex>
      </v-toolbar>
      <v-main class="step__main-body">
        <div class="templateDesktop">
          <v-container class="request__container">
            <v-stepper
              v-model="e6"
              :vertical="vertical"
            >
              <v-row :class="stepperStyle">
                <v-col
                  class="col-md-6"
                  align-self="center"
                >
                  <div
                    class="stepperDesktop"
                  >
                    <div
                      :class="
                        step_index === step_loan_request
                          ? 'line active'
                          : 'line'
                      "
                    />
                    <div
                      :class="
                        step_index === step_quotation ? 'line active' : 'line'
                      "
                    />
                    <div
                      :class="
                        step_index === step_personal_information
                          ? 'line active'
                          : 'line'
                      "
                    />
                    <div
                      :class="
                        step_index === step_documentation
                          ? 'line active'
                          : 'line'
                      "
                    />
                    <div
                      :class="
                        step_index === step_disbursment ? 'line active' : 'line'
                      "
                    />
                    <div
                      :class="
                        step_index === step_check_data ? 'line active' : 'line'
                      "
                    />
                  </div>
                </v-col>
              </v-row>
              <v-stepper-items>
                <v-stepper-content step="1">
                  <loan-request-data
                    v-if="step_index === step_loan_request"
                    ref="LoanRequestData"
                    :card="card_index"
                    :title="title"
                    @send-message="handleStep"
                  />
                </v-stepper-content>
                <v-stepper-content step="2">
                  <quotation-data
                    v-if="step_index === step_quotation"
                    ref="QuotationData"
                    @send-message="handleStep"
                  />
                </v-stepper-content>
                <v-stepper-content step="3">
                  <personal-information
                    v-if="step_index === step_personal_information"
                    ref="PersonalInformation"
                    @send-message="handleStep"
                  />
                </v-stepper-content>
                <v-stepper-content
                  step="4"
                  class="headerRoundedWhite main__content--padding"
                >
                  <div>
                    <main-data
                      v-if="step_index === step_documentation_main"
                      ref="MainData"
                      :card="card_index"
                      @send-message="handleStep"
                    />
                  </div>
                </v-stepper-content>
                <v-stepper-content step="5">
                  <documentation-data
                    v-if="step_index === step_documentation"
                    ref="DocumentationData"
                    :card="card_index"
                    @send-message="handleStep"
                  />
                </v-stepper-content>
                <v-stepper-content step="6">
                  <disbursement-data
                    v-if="step_index === step_disbursment"
                    ref="DisbursementData"
                    :card="card_index"
                    @send-message="handleStep"
                  />
                </v-stepper-content>
                <v-stepper-content
                  step="7"
                  class="headerRoundedWhite main__content--padding"
                >
                  <check-data
                    v-if="step_index === step_check_data"
                    ref="datosCheckIn"
                    @send-message="handleStep"
                  />
                </v-stepper-content>
                <v-stepper-content
                  step="8"
                  class="headerRoundedWhite main__content--padding"
                >
                  <finish-loan-request
                    v-if="step_index === step_finish_loan_request"
                    ref="FinishLoanRequest"
                    @send-message="handleStep"
                  />
                </v-stepper-content>
              </v-stepper-items>
                  <v-btn
                    bottom
                    depressed
                    rounded
                    class="btnWppAyuda float-right"
                    @click="$store.state.redirectToWhastapp()"
                  >
                    Ayuda
                    <v-img
                      class="imageWppAyuda"
                      src="@/assets/img/icon_whatsapp_ayuda.png"
                      alt="wpp"
                    />
                  </v-btn>

              <loading-dialog />
              <v-snackbar
                v-model="snackbar"
                :bottom="true"
                :color="color"
                :timeout="timeout"
              >
                {{ text }}
                <template #action="{ attrs }">
                  <v-btn
                    dark
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Cerrar
                  </v-btn>
                </template>
              </v-snackbar>
            </v-stepper>
          </v-container>
        </div>
      </v-main>
    </v-app>
    <v-dialog
      v-model="error"
      persistent
      width="350px"
    >
      <ErrorRequest />
    </v-dialog>
    <v-dialog
      v-model="warning"
      persistent
      width="350px"
    >
      <WarningModal />
    </v-dialog>
    <v-dialog
      v-model="information"
      persistent
      width="350px"
    >
      <InformationModal />
    </v-dialog>
  </div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import ErrorRequest from "@/components/popUps/ErrorRequest";
import InformationModal from "@/components/popUps/Information";

export default {
  name: "Solicitud",
  components: {
    InformationModal,
    LoanRequestData: () => import("./components/LoanRequestData"),
    PersonalInformation: () => import("./components/PersonalInformation"),
    DocumentationData: () => import("./components/DocumentationData"),
    QuotationData: () => import("./components/QuotationData"),
    DisbursementData: () => import("./components/DisbursementData"),
    CheckData: () => import("./components/CheckData"),
    MainData: () => import("./components/MainData"),
    FinishLoanRequest: () => import("./components/FinishLoanRequest"),
    headers: () => import("./components/Headers"),
    ErrorRequest: () => import("../../components/popUps/ErrorRequest"),
    WarningModal: () => import("../../components/popUps/Warning"),
  },
  props: {
    step: { type: Number, default: 1 },
    card: { type: Number, default: 1 },
    title: { type: String, default: "Registrarse" },
  },
  data: (vm) => ({
    baseUrl: "api/solicitudes",
    e6: null,
    editedIndex: -1,
    steps: 5,
    vertical: false,
    valid: true,
    checkbox: false,
    snackbar: false,
    form: {
      datosPersonales: {},
      solicitud: {},
      checkList: {},
      cotizacion: {},
      desembolso: {},
    },
    formDatosPersonales: {},
    formDatosSolicitud: {},
    formDatosFotos: {},
    formDatosCheckList: {},
    formDatosDesembolso: {},
    formDatosSolicitudTerminada: {},
    financiaDesembolso: 0,
    formCotizacion: {
      quantity: "",
      amount: "",
    },
    text: "Se ha registrado una nueva solicitud",
    color: "success",
    timeout: 4000,
    step_index: 1,
    step_loan_request: 1,
    step_quotation: 2,
    step_personal_information: 3,
    step_documentation_main: 4,
    step_documentation: 5,
    step_disbursment: 6,
    step_check_data: 7,
    step_finish_loan_request: 8,
    card_aux: "",
    available: false,
  }),
  computed: {
    hideStepper() {
      let hideQuotation = this.step_index === this.step_quotation;
      let hideMainDni = this.step_index === this.step_documentation_main;
      let hideCheckDataStepper = this.step_index === this.step_check_data;
      let hideFinishLoanRequestStepper =
        this.step_index === this.step_finish_loan_request;
      return (
        hideQuotation ||
        hideMainDni ||
        hideCheckDataStepper ||
        hideFinishLoanRequestStepper
      );
    },
    hideStepperDesktop() {
      let hideMainDni = this.step_index === this.step_documentation_main;
      let hideCheckDataStepper = this.step_index === this.step_check_data;
      let hideFinishLoanRequestStepper =
        this.step_index === this.step_finish_loan_request;
      return (
        hideMainDni || hideCheckDataStepper || hideFinishLoanRequestStepper
      );
    },
    hideHeader() {
      let hideMainDniStepper = this.step_index === this.step_documentation_main;
      let hideCheckDataStepper = this.step_index === this.step_check_data;
      let hideFinishLoanRequestStepper =
        this.step_index === this.step_finish_loan_request;
      return (
        hideMainDniStepper ||
        hideCheckDataStepper ||
        hideFinishLoanRequestStepper
      );
    },

    card_index: {
      get() {
        if (this.card_aux === "") {
          this.card_aux = this.card;
        }
        return this.card_aux;
      },
      set(value) {
        this.card_aux = value;
      },
    },
    error() {
      return this.$store.getters.getErrorModal;
    },
    warning() {
      return this.$store.getters.getWarning;
    },
    information() {
      return this.$store.getters.getInformation;
    },
    stepperStyle() {
       return this.$store.getters.getStepperStyle;
    }
  },
  watch: {
    created() {
      this.$store.commit("SET_DEVICE");
    },
  },
  mounted() {
    this.e6 = this.step;
    this.step_index = this.step;
    this.card_index = this.card;
    this.$gtm.trackView("Solicitud", "/registro/iniciar");
    this.checkMbbx(this.step);

  },
  methods: {
    ...mapGetters(["getCardStepDocumentation"]),
    handleStep(step, value = 1, formStep = false) {
      this.e6 = step;
      this.card_aux = value;
      this.checkStyle();
      this.checkMbbx(step);
      switch (step) {
        case "1":
          this.step_index = this.step_loan_request;
          this.formDatosSolicitud = formStep;

          break;
        case "2":
          this.step_index = this.step_quotation;
          this.formDatosPersonales = formStep;
          break;
        case "3":
          this.step_index = this.step_personal_information;
          break;
        case "4":
          this.step_index = this.step_documentation_main;
          this.formDatosFotos = formStep;
          break;
        case "5":
          this.step_index = this.step_documentation;
          this.card_aux = this.getCardStepDocumentation();
          this.formDatosCheckList = formStep;
          break;
        case "6":
          this.step_index = this.step_disbursment;
          this.formDatosDesembolso = formStep;
          break;
        case "7":
          this.step_index = this.step_check_data;
          this.formDatosDesembolso = formStep;
          break;
        case "8":
          this.step_index = this.step_finish_loan_request;
          this.formDatosDesembolso = formStep;
          break;
      }
    },
    checkStyle() {
      if (
        this.step === 1 &&
        this.card === 2 &&
        !!document.getElementById("material-css") == false
      ) {
        let addCss = document.createElement("link");
        addCss.setAttribute("rel", "stylesheet");
        addCss.setAttribute("id", "material-css");
        addCss.setAttribute(
          "href",
          "https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css"
        );
        document.head.appendChild(addCss);

        let addCss2 = document.createElement("link");
        document.head.appendChild(addCss2);
        addCss2.setAttribute("rel", "stylesheet");
        addCss2.setAttribute("href", "/material/scss/material-dashboard.scss");

        document.head.appendChild(addCss2);
      }
    },
    checkMbbx(step) {
      if(step !== "6"){return;}
      let addScript = document.createElement("script");
      addScript.src = `https://res.mobbex.com/js/embed/mobbex.embed@1.0.20.js?t=${Date.now()}`;
      addScript.async = true;
      addScript.crossorigin = "anonymous";
      addScript.setAttribute("crossorigin", "anonymous");
      document.body.appendChild(addScript);
    }
  },
};
</script>
