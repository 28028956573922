import {userRoute} from "@/helper/userRoute"
import ManagementLoan from "@/views/gestion/ManagmentLoan";
import statusLoan from "@/components/manage_user/statusLoan";
import MyLoans from "@/views/gestion/MyLoans";
import NewCredit from "@/views/gestion/NewCredit";
import MyData from "@/views/gestion/MyData";
import ContactUs from "@/views/gestion/ContactUs";
import auth from "@/middleware/auth";
import ClientNewLoan from "@/components/manage_user/ClientNewLoan";
const user = [
    {
        path: '/user/home',
        name: userRoute.USER_HOME,
        component: ManagementLoan,
        children: [
            {
                path: 'statusLoan',
                name: userRoute.STATUS_LOAN,
                component: statusLoan,
                meta: {
                    middleware: [auth]
                }
            },
            {
                path: 'myloans',
                name: userRoute.MY_LOANS,
                component: MyLoans,
                meta: {
                    middleware: [auth]
                }
            },
            {
                path: 'newLoan',
                name: userRoute.NEW_LOAN,
                component: ClientNewLoan,
                meta: {
                    middleware: [auth]
                }
            },
            {
                path: 'newCredit',
                name: userRoute.NEW_CREDIT,
                component: NewCredit,
                meta: {
                    middleware: [auth]
                }
            },
            {
                path: 'myData',
                name: userRoute.MY_DATA,
                component: MyData,
                meta: {
                    middleware: [auth]
                }
            },
            {
                path: 'contactUs',
                name: userRoute.CONTACT_US,
                component: ContactUs,
                meta: {
                    middleware: [auth]
                }
            }
        ],
    },
]

export default user