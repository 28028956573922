export const creditStatus = Object.freeze({
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  ACCEPTED: 'ACCEPTED',
  DOCUMENTING: 'DOCUMENTING',
  OPENED: 'OPENED',
  REJECTED: 'REJECTED',
  CLOSED: 'CLOSED',
  DELAYED: 'DELAYED',
  BAD: 'BAD',
  COMPLETED: 'COMPLETED'
});