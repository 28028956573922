<template>
  <div>
    <div v-if="showCongratulations">
      <div
        align="center"
        justify="center"
        style="padding: 0px !important; width: 88%"
      >
        <div class="col-md-4 col-xs-12">
          <v-img
            class="mx-auto"
            src="@/assets/img/pet_happy_2.svg"
            alt="Mascota Estado"
            position="center center"
            contain
            max-height="400"
            min-height="400"
          />
        </div>

        <div
          class="col-md-8 col-xs-12"
          align="center"
          justify="center"
        >
          <h1 class="stepLoan--textImg">
            ¡Felicitaciones!
          </h1>
          <div>
            Por ser un excelente cliente tenés un
            <b>nuevo préstamo pre-aprobado.</b>
          </div>
          <br>
          <br>
          <v-btn
            bottom
            depressed
            rounded
            x-large
            class="btnAmarillo divHomeTopMargin"
            @click="clientAskForNewLoan"
          >
            Pedir Nuevo Prestamo
          </v-btn>
        </div>
      </div>
    </div>

    <div v-if="card_option_loan">
      <v-row
        align="center"
        justify="center"
      >
        <div
          class="col-md-6 col-xs-12"
          align="center"
          justify="center"
        >
          <v-sheet
            class="panel__slider roundedSheetHome paddingSheetHome"
            max-width="80%"
            width="100%"
          >
            <h4 class="panel__slider-title textTitleNonBold">
              ¿Cuánto queres pedir?
            </h4>
            <v-skeleton-loader
              v-if="amountLoader"
              transition="fade-transition"
              type="table-cell"
            />
            <h3 v-else>
              ${{ Number(formDue.amount).toLocaleString("es-AR") }}
            </h3>
            <v-slider
              v-model="formDue.amount"
              :step="amount_step"
              :min="min_amount"
              :max="max_amount"
              :color="formDue.color"
              :track-color="formDue.trackColor"
              height="4px"
            />
            <v-row>
              <v-col
                align="left"
                justify="left"
              >
                <h4
                  class="textBottomSliderLeft"
                  textTitleNonBold
                >
                  ${{ min_amount }}
                </h4>
              </v-col>
              <v-col
                align="right"
                justify="right"
              >
                <h4 class="textBottomSliderRightAmount textTitleNonBold">
                  ${{ max_amount }}
                </h4>
              </v-col>
            </v-row>

            <div>
              <h4 class="panel__slider-amount textTitleNonBold">
                ¿En cuántos meses lo querés pagar?
              </h4>
              <v-skeleton-loader
                v-if="amountLoader"
                transition="fade-transition"
                type="table-cell"
              />
              <h3 v-else>
                {{ formDue.installment }}
              </h3>
              <v-slider
                v-model="formDue.installment"
                :step="installment_step"
                :min="min_installment"
                :max="max_installment"
                :color="formDue.color"
                :track-color="formDue.trackColor"
                height="4px"
              />
              <v-row>
                <v-col
                  align="left"
                  justify="left"
                >
                  <h4 class="textBottomSliderLeft textTitleNonBold">
                    {{ min_installment }}
                  </h4>
                </v-col>
                <v-col
                  align="right"
                  justify="right"
                >
                  <h4 class="textBottomSliderRightQuotation textTitleNonBold">
                    {{ max_installment }}
                  </h4>
                </v-col>
              </v-row>
            </div>
          </v-sheet>
        </div>
        <div
          align="center"
          justify="center"
          class="col-md-6 col-xs-12 client-new-loan__detail"
        >
          <v-card>
            <v-simple-table>
              <tbody>
                <tr>
                  <td>Plazo de devolucion</td>
                  <td class="inputOTP">
                    <v-skeleton-loader
                      v-if="amountLoader"
                      type="table-cell"
                    />
                    <div v-else>
                      {{ installments }} meses
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Monto de cada cuota</td>
                  <td class="inputOTP">
                    <v-skeleton-loader
                      v-if="amountLoader"
                      transition="fade-transition"
                      type="text"
                    />
                    <div v-else>
                      {{ installments }} de $
                      {{ Number(avgInstallment).toLocaleString("es-AR") }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Tasa Nominal Anual (TNA)</td>
                  <td class="inputOTP">
                    <v-skeleton-loader
                      v-if="amountLoader"
                      transition="fade-transition"
                      type="table-cell"
                    />
                    <div v-else>
                      {{ interestRate }}%
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Costo Financiero Total (CFTEA con IVA)</td>
                  <td class="inputOTP">
                    <v-skeleton-loader
                      v-if="amountLoader"
                      transition="fade-transition"
                      type="table-cell"
                    />
                    <div v-else>
                      {{ cft }}%
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-toolbar-title class="subheading footerMounted">
              COMENZÁ A PAGAR EN {{ payment_month }}
            </v-toolbar-title>
          </v-card>
        </div>
      </v-row>
      <v-row>
        <v-col
          align="center"
          justify="center"
          class="client-new-loan__btn-continue"
        >
          <v-btn
            bottom
            depressed
            rounded
            class="btnAmarillo divHomeTopMargin"
            @click="startLoanClient()"
          >
            Confirmar
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="card_go_app">
      <ClientGoApp />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {homeRoute} from "../../helper/homeRoute";
import ClientGoApp from "./ClientGoApp";

export default {
  name: "ClientNewLoan",
  components: {ClientGoApp},
  data: () => ({
    showCongratulations: true,
    interestRate: null,
    formDue: {
      amount: 3000,
      installment: 12,
      avgInstallment: "",
      date: "",
      color: "#000000",
      trackColor: "#F0F0F0",
      loanId: null,
    },
    amount_step: null,
    min_amount: null,
    max_amount: null,
    min_installment: null,
    max_installment: null,
    installment_step: null,
    optionsSlider: {},
    amountLoader: true,
    payment_month: null,
    payment_day: null,
    loanConfig: null,
    card_option_loan: false,
    card_go_app:false
  }),
  computed: {
    avgInstallment() {
      if (this.optionsSlider[this.formDue.installment]) {
        return this.optionsSlider[this.formDue.installment][this.formDue.amount]
          .credit.avgInstallment;
      }
      return "-";
    },
    cft() {
      if (this.optionsSlider[this.formDue.installment]) {
        return this.optionsSlider[this.formDue.installment][this.formDue.amount]
          .credit.cft;
      }
      return "-";
    },
    installments() {
      if (this.optionsSlider[this.formDue.installment]) {
        return this.optionsSlider[this.formDue.installment][this.formDue.amount]
          .installments;
      }
      return "-";
    },
    credit() {
      if (this.optionsSlider[this.formDue.installment]) {
        return this.optionsSlider[this.formDue.installment][this.formDue.amount]
          .credit;
      }
      return "-";
    },
  },
  mounted() {
    this.$gtm.trackEvent({
      noninteraction: false,
      event: `WEB_ARG_RENOVADOR_CREDIT_APPROVED`,
      id_person: this.$store.getters.getUserForm,
    });
    let today = moment();
    this.payment_month = today.add(1, "month").format("MMMM").toUpperCase();
    let dayDate = today.date();
    let firstPayment = null;
    this.$apiserver.getCreditUser().then((res) => {
      let isMutual = this.$business.MUTUAL_ID_CONFIG.includes(res.configCredit);
      if(isMutual) {
        let form = {card_input: false};
        this.$store.commit('SET_DISBURSMENT_AVAILABILITY', form)
      }
      this.min_amount = res.creditAmountMin;
      this.max_amount = res.creditAmountMax;
      this.amount_step = res.creditAmountStep;
      this.min_installment = res.totalInstallmentsMin;
      this.max_installment = res.totalInstallmentsMax;
      this.installment_step = res.totalInstallmentsStep;
      this.interestRate = res.interestRate;
      this.formDue.installment = this.min_installment + this.installment_step;
      this.formDue.amount = this.min_amount + this.amount_step * 2;
      this.amountLoader = false;
      this.loanConfig = res.configCredit;
      this.payment_day = res.monthlyExpirationDay;
      this.optionsSlider = res.options;
      this.setMainData({
        selfie: res.needSelfie,
        dniPhoto: res.needDni,
      });
      if (dayDate >= res.firstPaymentCuttingDay) {
        firstPayment = today.add(1, "month");
        this.payment_month = firstPayment.format("MMMM").toUpperCase();
      }
    });
  },
  methods: {
    ...mapActions(["setLoan", "setMainData", "openLoader", "closeLoader", "logout", "logIn"]),
    startLoanClient() {
      let goApp = process.env.VUE_APP_CLIENT_NEW_LOAN_GO_APP === '1';
      if(goApp) {return;}
      this.formDue.avgInstallment = this.avgInstallment;
      this.formDue.installment = this.installments;
      let creditSelected = {
        avgInstallment: this.credit.avgInstallment,
        amountInterests: this.credit.amountInterests,
        amountAdminCost: this.credit.amountAdminCost,
        amountIva: this.credit.amountIva,
        amountInsurance: this.credit.amountInsurance,
        amountStampTax: this.credit.amountStampTax,
        cft: this.credit.cft,
      };
      let planSelected = {
        installments: this.installments,
        amount: this.formDue.amount,
        credit: creditSelected,
      };
      let infoCredit = {
        credit: this.loanConfig,
        monthlyExpirationDay: this.payment_day,
      };
      this.openLoader();
      this.$apiserver
        .saveCredit(infoCredit, planSelected)
        .then((res) => {
          this.logout();
          this.logIn(null);
          if (res.message) {
            this.formDue.loanId = Number(res.message);
          }
          this.setLoan(this.formDue);
          this.$gtm.trackEvent({
            noninteraction: false,
            event: `WEB_ARG_RENOVADOR_CREDIT_INFO`,
            id_person: this.$store.getters.getUserForm,
          });
          this.$router.push({ name: "request_client_information" });
        })
        .catch((error) => {
          this.$store.commit("ERROR_MODAL", true);
          console.warn(error.message);
        })
        .finally(() => this.closeLoader());
    },
    clientAskForNewLoan() {
      let goApp = process.env.VUE_APP_CLIENT_NEW_LOAN_GO_APP === '1';
      if(goApp) {
        this.showCongratulations=false;
        this.card_go_app=true;
        return;
      }
      //TODO verificar si el envio del formulario al momento de que el renovador solicite un nuevo prestamo desde la web
      this.$apiserver.sendFormData({}, this.$apiserver.newCreditType());
      this.card_option_loan = true;
    }
  },
};
</script>

<style scoped>
</style>